export default {
  productMapping(maps) {
    // ProductCreateModal.vue
    // ProductEditModal.vue

    // maps จะเป็นอาร์เรย์ที่มีค่าสำคัญอยู่สองตัวคือ 1. Property 2. Value
    // เราจะใช้ฟังก์ชั่น flatMap ในการช่วยสร้าง Object ตัวใหม่จากอาร์เรย์ maps
    // โดยเราจะทำการตรวจสอบว่า map.property มีค่าหรือไม่ ถ้าไม่มีเราจะส่งเป็นอาร์เรย์เปล่ากลับไป ก็คือการลบ Property ตัวนั้นทิ้ง
    // ถ้าหากว่ามีค่า เราจะทำการส่ง Object ตัวใหม่ออกไป โดยเราจะเอาค่าของ map.property มาทำเป็น property ตัวใหม่
    // ด้วยการใช้คำสั่ง [map.property]

    const flatMap = maps.flatMap(map => ((map.property) ? { [map.property]: map.value } : []))

    // ทำการรวม Object แต่ล่ะตัวในอาร์เรย์ให้กลายเป็นตัวเดียวกัน
    let output = {}
    flatMap.forEach(map => {
      output = { ...output, ...map }
    })

    return output
  },

  productUnmapping(maps) {
    // ProductEditModal.vue

    // maps จะเป็น Object ที่เราจะต้องแยก Property และ Value ออกมาสร้างเป็นอาร์เรย์ตัวใหม่
    // โดยเราจะใช้ Object.keys และ Object.values เพื่อช่วยแยกค่าทั้งสองออกจากกัน

    if (maps) {
      const properties = Object.keys(maps)
      const values = Object.values(maps)

      const output = []
      properties.forEach((property, index) => {
        const key = { id: index + 1, property, value: values[index] }
        output.push(key)
      })

      return output
    }

    return []
  },

  getVueSelectMultipleFormatById(preProcessing) {
    if (preProcessing && preProcessing.length > 0) {
      return preProcessing.map(data => data.id)
    }

    return []
  },

  getVueSelectMultipleFormatByName(preProcessing) {
    if (preProcessing && preProcessing.length > 0) {
      return preProcessing.map(data => data.name)
    }

    return []
  },
}
